.headerTestTitle {
  color: var(--white);
  font-weight: bold;
  font-size: 16px;
  line-height: 27px;
  padding: 12px 30px;
  text-align: center;
  background: var(--orange);
  transition: 0.3s ease all;
}
